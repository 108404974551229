<template>
  <div>
    <div class="d-flex">
      <div class="w-100">
        <div class="d-flex justify-content-center">
          <!-- 1 -->
          <div class="w-75">
            <!-- <h6 class="text-center">VER</h6> -->
            <b-input-group class="mb-1">
              <b-input-group-append
                v-b-tooltip.v-secondary.auto.noninteractive="
                  `
                  ${
                    !checkbox_1
                      ? 'Seleccionar todos (incluyendo los que se creen posteriormente)'
                      : 'Deseleccionar todos'
                  }
                `
                "
              >
                <b-form-checkbox
                  class="mt-1"
                  v-model="checkbox_1"
                  @change="
                    () => {
                      if (checkbox_1)
                        can_do_special_behavior = Items.map((x) => x.id);
                      else this.can_do_special_behavior = [];
                    }
                  "
                  name="checkbox-1"
                >
                </b-form-checkbox>
              </b-input-group-append>
              <b-form-input
                v-model="input_search1"
                type="search"
                size="sm"
                :disabled="checkbox_1"
                :placeholder="`Buscar ${Name}...`"
              >
              </b-form-input>
              <b-input-group-append>
                <b-button
                  :disabled="!input_search1"
                  @click="input_search1 = ''"
                  size="sm"
                  >Limpiar</b-button
                >
              </b-input-group-append>
            </b-input-group>
            <b-form-select
              :disabled="checkbox_1"
              :options="searchItems1"
              text-field="label"
              value-field="id"
              v-model="can_do_special_behavior"
              size="sm"
              :select-size="searchItems1.length < 7 ? searchItems1.length : 7"
              multiple
            ></b-form-select>
          </div>
          <!-- acction -->
          <div class="ml-2 d-flex align-center">
            <b-button
              size="sm"
              variant="none"
              v-b-tooltip.v-secondary.auto.noninteractive="`Guardar Permisos`"
              @click="saveAccessTable"
            >
              <b-icon-box-arrow-in-down
                class="h2 mb-0"
                font-scale="1.7"
              ></b-icon-box-arrow-in-down>
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toast, generateUniqueId } from "@/utils/utils";
export default {
  name: "SpecialAccessCard",
  components: {},
  props: {
    Table: {
      type: Object,
      required: true,
    },
    Access: {
      type: Object,
      required: true,
    },
    Items: {
      type: Array,
    },
    TableAccess: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          table: null,
          access: null,
          can_create: false,
          can_read: "",
          can_update: "",
          can_delete: "",
          can_do_special_behavior: "",
        };
      },
    },
    Name: {
      type: String,
      default: "",
    },
    allows_crud: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      table_access: { ...this.TableAccess },
      can_do_special_behavior: [],
      checkbox_1: false,
      input_search1: "",
    };
  },
  computed: {
    searchItems1: function () {
      return this.Items.map((x) => {
        return { ...x, label: x.code + "  " + x.name };
      })
        .filter((x) => {
          return (
            this.$filtered(x.label, this.input_search1) ||
            this.can_do_special_behavior.includes(x.id)
          );
        })
        .sort(function (a, b) {
          return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
        });
    },
  },
  methods: {
    saveAccessTable() {
      this.table_access.table = this.Table.id;
      this.table_access.access = this.Access.id;
      if (this.checkbox_1) this.table_access.can_do_special_behavior = "all";
      else {
        this.can_do_special_behavior =
          this.can_do_special_behavior.filter(Number);
        this.table_access.can_do_special_behavior =
          this.can_do_special_behavior.toString();
      }
      if (!isNaN(this.Table.id) && !isNaN(this.Access.id)) {
        if (isNaN(this.table_access.id)) this.createTableAccess();
        else this.updateTableAccess();
      }
    },
    createTableAccess() {
      this.$restful
        .Post(`/teaching/table-access/`, this.table_access)
        .then((response) => {
          this.table_access = response;
          toast("Permiso actualizado");
        });
    },
    updateTableAccess() {
      this.$restful
        .Patch(
          `/teaching/table-access/${this.table_access.id}/`,
          this.table_access
        )
        .then((response) => {
          this.table_access = response;
          toast("Permiso actualizado");
        });
    },
  },
  watch: {},
  mounted() {
    if (!isNaN(this.Table.id) && !isNaN(this.Access.id))
      this.$restful
        .Get(
          `/teaching/table-access/?table=${this.Table.id}&access=${this.Access.id}`
        )
        .then((response) => {
          if (response.length > 0) {
            this.table_access = response[0];
            if (response[0].can_do_special_behavior == "all") {
              this.checkbox_1 = true;
              this.can_do_special_behavior = this.Items.map((x) => x.id);
            } else
              this.can_do_special_behavior = response[0].can_do_special_behavior
                .split(",")
                .map(Number);
          }
        });
  },
  created() {},
};
</script>

<style scoped>
.div-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>